import { getAppStatus, LOAD_ERROR, triggerAppChange } from "single-spa";
import { logApplicationEvent, logApplicationError } from "../logger/ceme-logger";
import getActiveMFE from "./get-active-mfe";
import redirectToErrorPage from "./redirect-to-error-page";

const retries = {};
const myAccountMfe = "@allstate/myAccount";
const footerMfe = "@allstate/myAccount-footer";
let retryCount;

function handleMYALoadError(err, currentMfe) {
  const urlParameters = new URLSearchParams(new URL(window.location.href).search);
  const efmLoginExists = urlParameters.get("msgid") === 'efmlogin3';
  if (retryCount === 1 && !efmLoginExists) {
    logApplicationEvent({
      type: getAppStatus(err.appOrParcelName),
      name: err.appOrParcelName,
      description: `Network fetch retry count ${retryCount}. Refreshing the application.`,
      code: "MFE-EVT03"
    });
    const refreshUrl = new URL(window.location.href);
    if (window.location.pathname.includes("/poe/")) {
      refreshUrl.searchParams.append('msgid', 'efmlogin4');
    } else if (window.location.pathname.includes("/pre/")) {
      refreshUrl.searchParams.append('msgid', 'efmlogin5');
    } else {
      refreshUrl.searchParams.append('msgid', 'efmlogin3');
    }
    window.location.href = refreshUrl.toString();
  }
  if (efmLoginExists && currentMfe.error.redirectOnLoadError) {
    redirectToErrorPage(currentMfe, null);
  }
}

function handleMFELoadError(err, currentMfe) {
  if (retryCount >= 2
    && (currentMfe.mountingRoutes.includes(window.location.pathname)
        || err.appOrParcelName === myAccountMfe
        || err.appOrParcelName === footerMfe)
    && currentMfe.error.redirectOnLoadError
  ) {
    redirectToErrorPage(currentMfe, retryCount);
    retryCount += 1;
    retries[err.appOrParcelName] = retryCount;
  }

  if (retryCount <= 1) {
    logApplicationEvent({
      type: getAppStatus(err.appOrParcelName),
      name: err.appOrParcelName,
      description: `Network fetch retry count ${retryCount}`,
      code: err.appOrParcelName === myAccountMfe ? `MFE-EVT02` : `MFE-EVT05`
    });
    setTimeout(() => {
      retryCount += 1;
      retries[err.appOrParcelName] = retryCount;
      triggerAppChange();
    }, 1000);
  }
}

export default function mfeRetry(err) {
  const mfeAppsMap = getActiveMFE(false); // unsorted
  const currentMfe = mfeAppsMap[err.appOrParcelName];
  retryCount = retries[err.appOrParcelName] || 0;

  logApplicationError({
    type: getAppStatus(err.appOrParcelName),
    name: err.appOrParcelName,
    object: err,
    description: "Error loading MFE",
    code: getAppStatus(err.appOrParcelName) === LOAD_ERROR ? "MFE-ERR04" : "MFE-ERR05"
  });

  if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
    window.System.delete(window.System.resolve(currentMfe.loader));
    if (err.appOrParcelName === myAccountMfe) {
      handleMYALoadError(err, currentMfe);
    }
    handleMFELoadError(err, currentMfe);
  }
}
