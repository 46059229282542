import mfeAppsMap from "./mfe.json";
import mfeNativeAppsMap from "./native-mfe.json";
import mfeDaAppsMap from "./mfe-da.json";
import getContext from "../communication/get-context";

export default function getActiveMFE(sorted = true) {
  const mfeArray = [];
  let mfeMap;
  const isChannelMob =
    getContext()?.channel === "mob" ||
    getContext()?.[0]?.channel === "mob" ||
    false;
  if (isChannelMob) {
    mfeMap = mfeNativeAppsMap.nativeMfes;
  } else if (
    getContext()?.channel === "household" ||
    getContext()?.[0]?.channel === "household"
  ) {
    mfeMap = mfeDaAppsMap.mfes;
  } else {
    mfeMap = mfeAppsMap.mfes;
  }
  const activeMFEObject = Object.getOwnPropertyNames(mfeMap).reduce(
    (result, key) => {
      if (mfeMap[key].active) {
        result[key] = mfeMap[key]; // eslint-disable-line no-param-reassign
        mfeArray.push(mfeMap[key]);
      }
      return result;
    },
    {}
  );
  if (!sorted) {
    return activeMFEObject;
  }

  return mfeArray.sort((a, b) => {
    return a.order - b.order;
  });
}
