import { logApplicationError } from "./logger/ceme-logger";
import registerStartApp from "./single-spa/register-start-app";
import addDivPlaceholdersForMFE from "./single-spa/add-div-placeholders-for-mfe";
import verifySupportedBrowser from "./single-spa/verify-supported-browser";

export default function exceptionHandler() {
  try {
    const urlParameters = new URLSearchParams(
      new URL(window.location.href).search
    );
    if (urlParameters.get("app") === "ceme") {
      verifySupportedBrowser();
    }
    addDivPlaceholdersForMFE();
    registerStartApp();
  } catch (error) {
    logApplicationError({
      type: "Error",
      name: "Digital Account Wallaby",
      object: error,
      description: "Global exception on Wallaby",
      code: "MFE-ERR02",
    });
  }
}
