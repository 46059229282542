/* eslint-disable indent */
import extractContext from "./extract-context";
import createContextObject from "./create-context-object";

const urlParameters = new URLSearchParams(new URL(window.location.href).search);

if (urlParameters.get("app") === "ceme" && urlParameters.get("ctx")) {
  window.history.replaceState(
    createContextObject({
      policyNumber: urlParameters.get("ctx"),
      channel: "mob",
      productAdminCd: urlParameters.get("pcd"),
      scenario: urlParameters.get("scenario"),
    }),
    "",
    window.location.pathname + window.location.search
  );
}

if (urlParameters.get("app") === "ceme" && urlParameters.get("context")) {
  const initialState = {};
  urlParameters.forEach((value, key) => {
    if (key === "context") {
      initialState.context = value;
      initialState.channel = "household";
    } else {
      initialState[key] = value;
    }
  });

  window.history.pushState(
    createContextObject(initialState),
    "",
    window.location.pathname
  );
}

let context = extractContext(window.history);

window.addEventListener("popstate", (event) => {
  context = extractContext(event);
});

export default function getContext() {
  return context;
}
