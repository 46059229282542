export default function handleUnsupportedBrowser() {
  // display unsupported browser page (same as noscript)
  const cookiesDisabled = document.getElementById("cookiesDisabledIndicator");
  const nos = document.getElementsByTagName("noscript")[0];
  const nosHtml = nos.innerHTML;
  const temp = document.createElement("div");
  temp.innerHTML = nosHtml;
  cookiesDisabled.appendChild(temp);

  const element = document.getElementById("wallaby-startupSpinner");
  element.parentNode.removeChild(element);
  window.history.pushState({}, null, "/anon/unsupportedbrowser");

  return cookiesDisabled;
}
