import mfeMap from "../single-spa/mfe.json";
import mfeNativeAppsMap from "../single-spa/native-mfe.json";
import getContext from "./get-context";

export default function getMfeRoutes() {
  let mfeRoutes;
  const channel = getContext()?.channel;
  if(channel && channel === "mob" ) {
    mfeRoutes = mfeNativeAppsMap.routes;
  } else {
    mfeRoutes = mfeMap.routes;
  }
  return mfeRoutes;
}
