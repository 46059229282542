import getActiveMFE from "./get-active-mfe";

export default function addDivPlaceholdersForMFE() {
  const activeMFEs = getActiveMFE();
  activeMFEs.forEach((mfe) => {
    const divContainerForMFE = document.createElement("div");
    divContainerForMFE.id = `single-spa-application:${mfe.name}`;
    divContainerForMFE.className = mfe.namespaceClassPrefix;
    document
      .querySelector("body")
      .insertAdjacentElement("beforeend", divContainerForMFE);
  });
}
