let correlationId;
function createSessionID() {
  const t = [];
  const r = Math.random;

  for (let i = 0; i < 30; i += 1) {
    t.push(((r() * 16) | 0).toString(16));
  }

  t.splice(8, 0, "-");
  t.splice(13, 0, "-4");
  t.splice(17, 0, "-" + (((r() * 4) | 0) + 8).toString(16));
  t.splice(21, 0, "-");
  return t.join("");
}

export default function getCorrelationId() {
  if (!correlationId) {
    correlationId = createSessionID();
  }
  return correlationId;
}
