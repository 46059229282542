import contextKey from "./context-key";

export default function extractContext(history) {
  if (!history || !history.state) {
    return undefined;
  }
  if (history.state[contextKey]) {
    // Angular history state
    return history.state[contextKey];
  }
  if (!history.state.state) {
    return undefined;
  }
  if (history.state.state[contextKey]) {
    // React history state
    return history.state.state[contextKey];
  }
  return undefined;
}
