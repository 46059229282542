import { getAppStatus } from "single-spa";
import getMfeRoutes from "../communication/get-mfe-routes";
import getContext from "../communication/get-context";
import createContextObject from "../communication/create-context-object";
import handleMFEError from "./handle-mfe-error";
import { logApplicationEvent, logApplicationError } from "../logger/ceme-logger";

export default function redirectToErrorPage(currentMfe, retryCount) {
  const redirectionRoute = getMfeRoutes()[currentMfe.error?.redirectRoute];

  switch (currentMfe.error?.redirectMethod) {
  case "history-api": {
    const MFE_ERROR_MSG = "We ran into a technical issue and couldn't load your information. This could be caused by a service failure or even a bad internet connection. Please try again. If you have made multiple attempts, give us a call or chat with a rep.";
    const contextObj = {
      ...getContext(),
      description: MFE_ERROR_MSG
    };
    if (retryCount <= 2) {
      logApplicationEvent({
        type: getAppStatus(currentMfe.name),
        name: currentMfe.name,
        description: `Network fetch retry count ${retryCount} . Hence redirected to stop page.`,
        code: "MFE-EVT06"
      });
    } else {
      logApplicationEvent({
        type: getAppStatus(currentMfe.name),
        name: currentMfe.name,
        description: `Wallaby DA Error Page 'Try Again' count ${retryCount - 2}`,
        code: "MFE-EVT07"
      });
    }
    handleMFEError(createContextObject(contextObj));
    break;
  }
  case 'href': {
    logApplicationEvent({
      type: getAppStatus(currentMfe.name),
      name: currentMfe.name,
      description: `Redirected to: ${redirectionRoute}.`,
      code: "MFE-EVT04"
    });
    window.location.href = redirectionRoute;
    break;
  }
  case "history-api-native": {
    const MFE_ERROR_MSG = "We ran into a technical issue and couldn't load your information. This could be caused by a service failure or even a bad internet connection. Please try again. If you have made multiple attempts, give us a call or chat with a rep.";
    const contextObj = {
      ...getContext(),
      description: MFE_ERROR_MSG
    };
    if (retryCount <= 2) {
      logApplicationEvent({
        type: getAppStatus(currentMfe.name),
        name: currentMfe.name,
        description: `Network fetch retry count ${retryCount} . Hence redirected to stop page.`,
        code: "MFE-EVT08"
      });
    } else {
      logApplicationEvent({
        type: getAppStatus(currentMfe.name),
        name: currentMfe.name,
        description: `Wallaby Native Error Page 'Try Again' count ${retryCount - 2}`,
        code: "MFE-EVT09"
      });
    }
    handleMFEError(createContextObject(contextObj));
    break;
  }
  default: {
    logApplicationError({
      type: getAppStatus(currentMfe.name),
      name: currentMfe.name,
      object: "",
      description: `No Error page is setup for ${currentMfe.name}`,
      code: "MFE-EVT00"
    });
  }
  }
}
